<!--
 * @Author: jiang
 * @Date: 2021-07-18 15:23:36
 * @Description: 新建修改 人员工作清单
-->
<template>
  <el-form
    ref="form"
    :model="formData"
    :rules="formRules"
    label-position="top"
  >
    <el-form-item v-if="!formData.id">
      <select-week @change="onWeekChange"></select-week>
    </el-form-item>
    <el-form-item
      label="本周工作总结"
      prop="week_summary"
    >
      <bus-editor v-model="formData.week_summary"></bus-editor>
    </el-form-item>
    <el-form-item
      label="下周工作计划"
      prop="next_week_plan"
    >
      <bus-editor v-model="formData.next_week_plan"></bus-editor>
    </el-form-item>
    <div class="g-center">
      <el-button
        :loading="submitLoading"
        type="primary"
        @click="onSubmit"
      >保存</el-button>
    </div>
  </el-form>
</template>

<script>
import SelectWeek from '@/components/select/week'
import BusEditor from '@/components/editor/textbus'

export default {
  components: {
    SelectWeek,
    BusEditor,
  },
  props: {
    data: Object,
    submitLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: {
        week_summary: '',
        next_week_plan: '',
      },
      year: '',
      month: '',
      week: '',
      formRules: {
        week_summary: { required: true, message: '必须填写', trigger: 'blur' },
        next_week_plan: { required: true, message: '必须填写', trigger: 'blur' },
      },
    }
  },
  watch: {
    data: {
      handler(val) {
        if (val) {
          this.formData = this.$tools.copy(val)
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    onWeekChange(data) {
      this.year = data.year
      this.month = data.month
      this.week = data.index
    },
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('confirm', {
            ...this.formData,
            year: this.year,
            month: this.month,
            week: this.week,
          })
        }
      })
    },
  },
}
</script>
